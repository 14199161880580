.title {
    font-weight: 400;
    font-size: 35px;
}

.rating {
}

.rating_contianer {
    display: flex;
}

.overview {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    height: 120px;
    overflow-y: hidden;
    margin-top: 10px;
}

.overview:hover {
    overflow-y: auto;
}
