.title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
}

.list {
  display: flex;
  width: calc(100vw - 50px);
  overflow-x: scroll;
  padding-bottom: 10px;
}
