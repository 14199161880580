.icon {
  position: absolute;
  margin-left: 15px;
  margin-top: 7px;
}

.input {
  border-radius: 30px;
  background-color: #d9d9d96e;
  height: 30px;
  color: white;
  font-weight: 100;
  font-size: 20px;
  width: 100%;
  border-color: transparent;
  padding: 18px 18px 18px 50px;
}

input::placeholder {
  color: lightgray;
}

input:focus {
  outline: none;
  border: 1px solid white;
}
